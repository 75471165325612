html{
    scroll-behavior: smooth;
}
body{
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
}

.accent {
   color: $accent-color;
   font-weight: 700;
}

.container{
    margin: 0 auto;
    display: block;

    &.normal{
        max-width: $container-width;
    }
    &.full{
        width: 100%;
    }
}

.clearfix{
    clear: both;
}

section{
    padding-top: $nav-height;
}

// Buttons
.btn{

    border-radius: $radi-big;
    padding: 10px 18px;

    &-accent{
        background-color: $accent-color;
        color: #fff;
        font-weight: bold;
    }
    
}



//text

.title{
    margin-bottom: $sp-big;

    span{
        display: block;
        // font-weight: bold;
        margin-bottom: -$sp-small;
        font-size: $fs-sub-title;
    }

    &-big{
        font-size: $fs-main-title;
        color: $accent-color;
        font-family: $f-accent;
        font-weight: 500;
    }
    &-deco{
        font-family: $f-accent;
        font-weight: bold;
        font-size: $fs-second-title;
        width: auto;
    }
    .deco-line{
        margin-top: 5px;
        span{
            height: 3px;
            display: block;
            margin: 0;
            &.small{
                width: 30px;
                background: $accent-color;
            }
            &.big{
                width: 100%;
                background: $accent-dark;
                margin-bottom: 2px;
                max-width: 125px;
            }
        }
    }
    h2{
        padding: 0;
        margin: 0;
    }
}