@media only screen and (max-width: 1200px){
   .container{
      padding: $sp-big;
   }
}

@media only screen and (max-width: 1150px){
   .gr-tow-columns{
      align-items: center;
        justify-items: center;
   }
   .gr-three-columns{
      grid-template-columns: auto auto;
      grid-template-rows: auto;
      align-items: center;
      justify-items: center;
   }

}

@media only screen and (max-width: 1050px){
   nav{

      .contact{
         right: 110px;
      }

      .nav-toggle{
         display: block;
         transition: ease .3s all;
         cursor: pointer;

         &.open{
            span{
               &:nth-child(1){
                  margin-bottom: -8px;
               }
            }
            span{
               &:nth-child(3){
                  margin-top: -8px;
               }
            }
         }
      }

      .main-nav{
         position: absolute;
         background: #fbfbfb;
         top: -100vh;
         height: auto;
         width: 100%;
         left: 0;
         pointer-events: none;
         transition: ease .3s all;
         z-index: 99;
         padding: 0;
         margin: 0;

         &.open{
            height: auto;
            opacity: 1;
            top: 140px;
            pointer-events: initial;

         }

         li{
            display: block;
            text-align: center;
            line-height: $sp-medium;
         }
      }

   }

   
}

@media only screen and (max-width: 840px){
   .gr-three-columns{
      grid-template-columns: auto;
      grid-template-rows: auto;
      align-items: center;
      justify-items: center;
   }
   .gr-two-columns{
      grid-template-columns: auto;
      grid-template-rows: auto;
      align-items: center;
        justify-items: center;
   }

   #diensten{
      text-align: center;
      img{
         margin: 0 auto;
         display: block;
      }
      .deco-line{
         .big{
            width: 125px;
         margin: 0 auto;

         }
         .small{
            width: 30px;
         margin: 5px auto 0 auto;

         }
      }
   }
   
   nav{
      .logo{
         left: $sp-medium;
         img{
            max-width: 80%;
         }
      }
      .nav-toggle{
         right: $sp-medium;
      }
   }

   .title{
      margin-bottom: $sp-medium;

      &-big{
         font-size: $fs-sub-title;
      }

      &-deco{
         font-size: $fs-sub-title;
      }

      span{
         margin-bottom: -2px;
      }
   }
   nav{
      height: 140px;

      .contact{
         position: absolute;
         top: 20px;
         display: block;
      }
   }

   .inner-nav{
      position: relative;
      top: 55px;
   }


      .divider.spacing-big{
         margin: $sp-medium 0;
      }
      #home{
         height: 50vh;
      }

      section{
         padding-top: $sp-medium;
      }

      #diensten{
         ul{
            li{
               font-size: $fs-small;
            }
         }
      }
}