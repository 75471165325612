#team{
    position: relative;
    // height: 100vh;
    .member{
        .image-container{
            display: inline-block;
            height: auto;
            width: 100%;
            max-width: 347px;
            position: relative;

            img{
                display: block;
                height: auto;
                width: 100%;
                max-width: 347px;
                transition: ease .3s filter;
            }
            &:hover{
               .image-container-overlay{
                  width: 80%;
                  height: 80%;
                  opacity: 1;
               }

               img{
                  filter: brightness(50%);
               }
            }

            &-overlay{
               width: 100%;
               height: 100%;
               border: 4px solid $accent-color;
               position: absolute;
               left: 0;
               top: 0;
               right: 0;
               bottom: 0;
               margin: auto;
               transition: ease .3s all;
               opacity: 0;

               .btn{
                  border: 2px solid $accent-color;
                  padding: $sp-small;
                  text-align: center;
                  margin: 0 auto;
                  margin-top: 50%;
                  transform: translateY(50%);
                  display: block;
                  border-radius: 0;
                  min-width: 60%;
                  background: none;
                  color: $accent-color;
                  font-size: $fs-big-text;
                  cursor: pointer;
                  transition: ease-in-out .3s all;
                  
                  &:hover{
                     background: $accent-color;
                     color: white;
                  }
               }
            }
        }
    }

    h4{
       max-width: 750px;
       margin-bottom: $sp-big;
    }

    .member-title{
       margin: $sp-small 0;
       h2{
          margin: 0;
       }

       span{
          font-size: $fs-small;
       }
    }

    .member-basic-info{
      min-height: 110px;
      h2{
         text-align: left;
      }
  
      ul{
         list-style: none;
         margin: 0;
         padding: 0;
         
         li{
           display: inline-block;
           font-size: 14px;
           height: 20px;
           margin-right: $sp-small;
  
           img{
              margin-bottom: -3px;
              margin-right: $radi-small;
           }
         }
        
      }
    }
}


.member-view{
   min-height: 200px;
   height: auto;
   width: auto;
   max-width: 500px;
   background-color: #fff;
   position: fixed;
   top: 40%;
   left: 0;
   right: 0;
   margin: 0 auto;
   z-index: 99999;
   opacity: 0;
   pointer-events: none;
   transition: ease-in-out .3s all;
   padding: $sp-big;
   transform: translateY(-50%);
   box-shadow: $default-shadow;
   border-radius: $radi-small;

   &.active{
      opacity: 1;
      top: 50%;
      pointer-events: all;
      background-color: #fefefe;
   }

   .close{
      position: absolute;
      right: -$sp-small;
      top: -$sp-small;
      cursor: pointer;
      width: 25px;
      height: 25px;
      color: white;
      background-color: $accent-color;
      font-weight: bold;
      line-height: 25px;
      text-align: center;
   }
}