#contact{
    margin-bottom: $nav-height;

    .contact-info{
        width: 100%;
        display: block;
        &-item{
            position: relative;
            height: 43px;
            float: left;
            margin-right: $sp-biggest;
            display: table-cell;
            vertical-align: middle;
            margin-bottom: $sp-medium;


            img{
                position: absolute;
                left: 0;
                top: 0;
            }
            span{
                margin-left: $sp-biggest;
                display: block;
            }
        }
    }
    .center-text{
        span{
            line-height: 43px;
        }
    }
}