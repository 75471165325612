@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,700;1,500&display=swap');

$accent-color:      #A9CE5A;
$accent-dark:       #4E5C2E;
$text-color:        #000;
$text-color-light:  #212121;
$danger:             #F55B5A;

// Fonts & sizes 
$fs-small:          14px;
$fs-text:           16px;
$fs-big-text:       18px;
$fs-main-title:     64px;
$fs-second-title:   38px;
$fs-sub-title:      24px;
$f-accent:          'Roboto Slab', serif;

// Spacing
$sp-small:          15px;
$sp-medium:         25px;
$sp-big:            45px;
$sp-biggest:        75px;
$nav-offset:        25px 90px;
$nav-height:        100px;

$radi-small:        4px;
$radi-big:          30px;

$container-width:   1200px;

$default-shadow:    0px 4px 4px rgba(0, 0, 0, 0.12)
   