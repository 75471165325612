.grid{
    display: grid;
    &.gap-small{
        grid-gap: $sp-small;
    }
    &.gap-medium{
        grid-gap: $sp-medium
    }
    &.gap-big{
        grid-gap: $sp-big;
    }
}
.gr-center{
    align-items: center;
}
.gr-two-columns{
    grid-template-columns: auto auto;
    grid-template-rows: auto;
}

.gr-three-columns{
   align-items: center;
   grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

.divider{
   height: auto;
   min-height: 100px;
   // background-color: red;
   // object-fit: cover;
   &.full-width{
      width: 100%;
   }
   &.spacing-big{
      margin: $sp-biggest 0;
   }
   img{
      max-width: 100%;
      height: auto;
   }
}