nav{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: $nav-height;
    background-color: white;
    box-shadow: $default-shadow;
    z-index: 100;
    opacity: 1;
    transition: ease .3s all;

    .nav-toggle{
       display: none;
       position: absolute;
       right: $sp-big;
       top: 35px;

       span{
         width: 40px;
         display: block;
         margin: 5px 0;
         height: 5px;
         background: $accent-color;
      }
    }

    &.hide{
      opacity: 0;
      height: 0;
      pointer-events: none;
      background-color: $accent-color;
    }

    ul{
        height: $nav-height;
        text-align: center;
        margin: 0;
        li{
            list-style: none;
            display: inline;
            padding: $sp-small;
            text-align: right;
            line-height: $nav-height;

            a{
                color: $text-color;
                font-weight: bold;
                text-decoration: none;
                font-size: $fs-big-text;
            }
        }
    }

    .contact{
        position: absolute;
        right: 80px;
        top: 32px;
        span{
            display: block;
            margin-top: 14px;
            text-align: center;
            font-weight: bold;
            font-size: $fs-small;
        }
    }

    .logo{
        position: absolute;
        left: 80px;
        top: 22px;
    }
}


footer{
   font-size: $fs-small;
}