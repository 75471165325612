#diensten{
    position: relative;
    .sub-nav{
        position: sticky;
        ul{
            margin: 0;
            padding: 0;
            margin-bottom: $sp-big;
            li{
                display: inline;
                padding: 0 $sp-medium 0 0;
            }
        }
    }

    .service{
        margin: $nav-height 0;
    }

    ul{
        list-style: none;
    }
}

.service-image{
    img{
        max-width: 100%;
    }
}